<template>
  <div>
    <Toolbar :handle-reset="resetForm" :handle-submit="onSendForm"/>
    <IssueForm
      :errors="violations"
      :values="item"
      refForm="updateForm"
      ref="updateForm"
      :handle-reset="resetForm"
      :handle-submit="onSendForm"
      :uploadAttachment="uploadAttachment"
      :deleteAttachment="deleteAttachment"
      :loading="isLoading"
      v-if="item"
    />
    <Loading :visible="isLoading || deleteLoading"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";
import IssueForm from "../../components/issue/Form.vue";
import Loading from "../../components/Loading";
import Toolbar from "../../components/Toolbar";
import UpdateMixin from "../../mixins/UpdateMixin";
import DocumentMixin from "../../mixins/DocumentMixin";
import NotificationMixin from "../../mixins/NotificationMixin";

const servicePrefix = "Issue";

export default {
  name: "IssueUpdate",
  servicePrefix,
  mixins: [DocumentMixin, NotificationMixin, UpdateMixin],
  data() {
    return {
      updateMessage: this.$t("issueUpdateSuccess"),
      attachmentDeletedMessage: this.$t("attachmentDeleted"),
      confirmAttachmentDeletionMessage: this.$t("confirmAttachmentDeletion"),
      updateRoute: null,
    };
  },
  components: {
    Loading,
    Toolbar,
    IssueForm,
  },
  computed: {
    ...mapFields("issue", {
      deleteLoading: "isLoading",
      isLoading: "isLoading",
      error: "error",
      deleted: "deleted",
      updated: "updated",
      violations: "violations",
    }),
    ...mapGetters("issue", ["find"]),
  },
  methods: {
    ...mapActions("issue", {
      deleteItem: "del",
      retrieve: "load",
      update: "update",
    }),
    uploadAttachment() {
      if (this.$refs.updateForm.attachment) {
        this.uploadDocument(this.$refs.updateForm.attachment, true);
      }
    },
    deleteAttachment(attachment) {
      this.$confirm(this.confirmAttachmentDeletionMessage, {
        buttonTrueText: this.$t('Yes, delete now'),
        buttonFalseText: this.$t('No'),
      }).then((response) => {
        if (!response) {
          return;
        }
        this.deleteDocument(
          attachment.iri ? {"@id": attachment.iri} : attachment
        );
        this.$store.dispatch('removeRelation', {
          iri: this.item['@id'],
          relationName: 'attachments',
          toIri: attachment['@id']
        });

        this.showMessage(this.attachmentDeletedMessage);
      });
    },
  },
  watch: {
    uploadedDocument() {
      if (!this.uploadedDocument) {
        return;
      }
      this.$store.dispatch('addRelation', {
        iri: this.item['@id'],
        relationName: 'attachments',
        toIri: this.uploadedDocument['@id']
      });
      this.$refs.updateForm.attachment = null;
    },
  },
};
</script>
